import React from 'react';
import Layout from './Layout';
import Sections from './Sections';

const Skills = () => {
  return <Layout sections={Sections} />;
};

Skills.propTypes = {};

export default Skills;
