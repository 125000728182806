import { withStyles } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/AccountBalance';
import UniversityIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import React from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Element from './Element';

const styles = (theme) => ({
  timeline: {
    width: '100%!important',
    margin: `${theme.spacing.unit * 2}px auto 0 auto`,
  },
  link: {
    color: theme.palette.secondary.light,
    textDecoration: 'none',
    transition: `color ${theme.transitions.duration.standard}ms`,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
});

const Timeline = (props) => {
  const { classes } = props;

  return (
    <VerticalTimeline className={classes.timeline}>
      <Element
        icon={<WorkIcon />}
        iconStyle='primary'
        title='Software-Entwickler .NET/Angular'
        subtitle='05/2024 - heute'
        contentItems={[
          {
            title: 'Unternehmen',
            content: (
              <a className={classes.link} rel='noopener noreferrer' href='https://www.grollmus.de/' target='_blank'>
                Grollmus GmbH
              </a>
            ),
          },
        ]}
      />
      <Element
        icon={<WorkIcon />}
        iconStyle='primary'
        title='Software Engineer'
        subtitle='08/2023 - 04/2024'
        contentItems={[
          {
            title: 'Unternehmen',
            content: (
              <a className={classes.link} rel='noopener noreferrer' href='https://www.adesso.de' target='_blank'>
                Adesso SE
              </a>
            ),
          },
        ]}
      />
      <Element
        icon={<WorkIcon />}
        iconStyle='primary'
        title='Software-Entwickler .NET/Angular'
        subtitle='08/2020 - 07/2023'
        contentItems={[
          {
            title: 'Unternehmen',
            content: (
              <a className={classes.link} rel='noopener noreferrer' href='https://www.grollmus.de/' target='_blank'>
                Grollmus GmbH
              </a>
            ),
          },
        ]}
      />
      <Element
        icon={<UniversityIcon />}
        iconStyle='secondary'
        title='Universität Passau'
        subtitle='2018 - 2020'
        contentItems={[
          {
            title: 'Master',
            content: 'Mobile & Embedded Systems',
          },
          {
            title: 'Werkstudent',
            content: (
              <a className={classes.link} rel='noopener noreferrer' href='https://smartricity.de' target='_blank'>
                Smartricity GmbH, Backend Developer
              </a>
            ),
          },
          {
            title: 'CrossFit Trainer',
            content: (
              <a
                className={classes.link}
                rel='noopener noreferrer'
                href='https://www.crossfit-passau.de/'
                target='_blank'>
                CrossFit Passau
              </a>
            ),
          },
        ]}
      />
      <Element
        icon={<UniversityIcon />}
        iconStyle='secondary'
        title='OTH Regensburg (Duales Studium)'
        subtitle='2013 - 2018'
        contentItems={[
          {
            title: 'Bachelor',
            content: 'Elektro- & Informationstechnik',
          },
          {
            title: 'Ausbildung',
            content: 'Fachinformatiker für Anwendungesentwicklung',
          },
          {
            title: 'Unternehmen',
            content: (
              <a
                className={classes.link}
                rel='noopener noreferrer'
                href='https://www.vector.com/de/de/'
                target='_blank'>
                Vector Informatik GmbH
              </a>
            ),
          },
        ]}
      />
      <Element
        icon={<SchoolIcon />}
        iconStyle='ternary'
        title='FOS Regensburg'
        subtitle='2012 - 2013'
        contentItems={[
          {
            title: 'Abschluss',
            content: 'Allgemeine Hochschulreife',
          },
        ]}
      />
      <Element
        icon={<SchoolIcon />}
        iconStyle='ternary'
        title='FOS Regen'
        subtitle='2010 - 2012'
        contentItems={[
          {
            title: 'Abschluss',
            content: 'Fachgebundene Hochschulreife',
          },
        ]}
      />
      <Element
        icon={<SchoolIcon />}
        iconStyle='ternary'
        title='Realschule Viechtach'
        subtitle='2004 - 2010'
        contentItems={[
          {
            title: 'Abschluss',
            content: 'Mittlere Reife',
          },
        ]}
      />
    </VerticalTimeline>
  );
};

Timeline.propTypes = {};

export default withStyles(styles)(Timeline);
