import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit,
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    padding: `${theme.spacing.unit * 0.75}px 0`,
    textDecoration: 'underline',
  },
});

const Section = (props) => {
  const { classes, title, leftPanel: LeftPanel, rightPanel: RightPanel, fullPanel: FullPanel } = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.title} align='center'>
        {title}
      </Typography>
      <Grid container>
        {LeftPanel && RightPanel && (
          <Fragment>
            <Grid item xs={12} sm={6}>
              <LeftPanel />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RightPanel />
            </Grid>
          </Fragment>
        )}
        {FullPanel && (
          <Fragment>
            <Grid item xs={12}>
              <FullPanel />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </div>
  );
};

Section.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  leftPanel: PropTypes.func,
  rightPanel: PropTypes.func,
  fullPanel: PropTypes.func,
};

export default withStyles(styles)(Section);
