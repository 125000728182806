import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    height: theme.typography.h5.fontSize,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  line: {
    backgroundColor: 'whitesmoke',
    height: '3px',
    margin: '5px',
    width: '25px',
    transition: 'all 0.3s ease',
  },
  firstLineTransition: {
    transform: 'rotate(-45deg) translate(-5px, 6px)',
  },
  secondLineTransition: {
    opacity: 0,
  },
  thirdLineTransition: {
    transform: 'rotate(45deg) translate(-5px, -6px)',
  },
});

const BurgerButton = (props) => {
  const { classes, isActive, onClick } = props;
  const firstLineStyles = isActive ? classNames(classes.line, classes.firstLineTransition) : classes.line;
  const secondLineStyles = isActive ? classNames(classes.line, classes.secondLineTransition) : classes.line;
  const thirdLineStyles = isActive ? classNames(classes.line, classes.thirdLineTransition) : classes.line;

  return (
    <div className={classes.root} onClick={onClick}>
      <div>
        <div className={firstLineStyles} />
        <div className={secondLineStyles} />
        <div className={thirdLineStyles} />
      </div>
    </div>
  );
};

BurgerButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(BurgerButton);
