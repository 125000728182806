import React, { Fragment } from 'react';
import CV from './CV';
import Introduction from './Introduction';
import Skills from './Skills';

const About = () => (
  <Fragment>
    <Introduction />
    <CV />
    <Skills />
  </Fragment>
);

About.propTypes = {};

export default About;
