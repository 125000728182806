import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const baseFontStyle = {
  color: 'whitesmoke',
  fontWeight: 'bold',
};

const baseFontSizeFactorStyle = (theme, startFactor) => ({
  fontSize: theme.typography.fontSize * startFactor,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.fontSize * (startFactor + 0.25),
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSize * (startFactor + 0.5),
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.fontSize * (startFactor + 0.75),
  },
});

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 50,
  },
  title: {
    ...baseFontStyle,
    ...baseFontSizeFactorStyle(theme, 1.75),
  },
  subtitle: {
    ...baseFontStyle,
    ...baseFontSizeFactorStyle(theme, 1.25),
  },
  button: {
    ...baseFontStyle,
    ...baseFontSizeFactorStyle(theme, 1),
    marginTop: theme.spacing.unit * 1.25,
    textTransform: 'none',
  },
  link: {
    textDecoration: 'none',
  },
});

const CallToAction = (props) => {
  const { classes, className } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <Typography className={classes.title} align='center'>
        Software-/Webdeveloper
      </Typography>
      <Typography className={classes.subtitle} align='center'>
        aus'm bayerischn Woid
      </Typography>
      <AnchorLink className={classes.link} href='#contact'>
        <Button className={classes.button} color='secondary' variant='contained'>
          Kontaktier den zünftign Bua!
        </Button>
      </AnchorLink>
    </div>
  );
};

CallToAction.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(CallToAction);
