import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    background: 'black',
  },
  typography: {
    color: 'whitesmoke',
    padding: `${theme.spacing.unit * 2}px 0`,
  },
});

const Footer = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.typography} align='center' variant='body1'>
        @powered by <strong>woidbua</strong>
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
