import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../../../assets/logos/woidbua.png';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    paddingRight: theme.spacing.unit * 2,
    margin: `${theme.spacing.unit * 2}px 0`,
    height: theme.typography.h3.fontSize,
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      height: theme.typography.h2.fontSize,
    },
  },
  typography: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.common.white,
    fontFamily: 'Lobster, cursive',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h2.fontSize,
    },
  },
});

const Logo = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <img className={classes.img} src={logo} alt='woidbua logo' />
      <Typography className={classes.typography}>woidbua</Typography>
    </div>
  );
};

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Logo);
