import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing.unit * 2}px 0`,
    margin: '0 auto',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.breakpoints.values.sm * 0.95,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.md * 0.9,
    },
  },
});

const Container = (props) => {
  const { classes, ...other } = props;
  return <div className={classes.root} {...other} />;
};

Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Container);
