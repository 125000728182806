import React, { Fragment } from 'react';
import Section from '../Section';
import Skill from '../Skill';

const Languages = () => {
  const leftPanel = () => (
    <Fragment>
      <Skill title='C#' percentage={90} usage={'Grollmus GmbH, Adesso SE, Vector Informatik GmbH, Studium, Private Projekte'} />
      <Skill title='JavaScript' percentage={75} usage={'Grollmus GmbH, Adesso SE, Private Projekte'} />
      <Skill title='Python' percentage={70} usage={'Smartricity GmbH, Private Projekte'} />
      <Skill title='Java' percentage={45} usage={'Studium (Masterarbeit)'} />
    </Fragment>
  );

  const rightPanel = () => (
    <Fragment>
      <Skill title='C++' percentage={30} usage={'Studium + Bachelorarbeit (Arduino)'} />
      <Skill title='C' percentage={25} usage={'Vector Informatik GmbH, Studium'} />
      <Skill title='Android' percentage={10} usage={'Studium, Private Projekte'} />
      <Skill title='PHP' percentage={10} usage={'Private Projekte'} />
    </Fragment>
  );

  return <Section title='Programmiersprachen' leftPanel={leftPanel} rightPanel={rightPanel} />;
};

Languages.propTypes = {};

export default Languages;
