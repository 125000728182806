import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  root: {
    background: 'rgba(0,0,0,0.25)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 100,
  },
  hide: {
    display: 'none',
  },
});

const Backdrop = (props) => {
  const { classes, isActive, onClick } = props;

  const rootClasses = isActive ? classNames(classes.root) : classNames(classes.root, classes.hide);

  return <div className={rootClasses} onClick={onClick} />;
};

Backdrop.propTypes = {
  classes: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Backdrop);
