import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const styles = (theme) => ({
  root: {
    background: 'linear-gradient(to bottom, transparent, rgba(0,0,0,0.25))',
    position: 'absolute',
    width: '100%',
    marginTop: 100,
    height: 220,
    top: 0,
    left: 0,
    overflowY: 'hidden',
    transition: `height ${theme.transitions.duration.standard * 1.5}ms`,
  },
  ul: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
  },
  li: {
    cursor: 'pointer',
    listStyleType: 'none',
    padding: `${theme.spacing.unit}px 0`,
    width: '100%',
    zIndex: 100,
  },
  typography: {
    color: 'whitesmoke',
    fontSize: theme.typography.fontSize * 1.75,
    transition: `color ${theme.transitions.duration.standard}ms`,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  link: {
    textDecoration: 'none',
  },
  hide: {
    height: 0,
  },
});

class MobileMenu extends Component {
  componentWillUnmount() {
    this.props.onClose();
  }

  render() {
    const { classes, isOpen, menuItems, onClose } = this.props;

    const rootClasses = isOpen ? classNames(classes.root) : classNames(classes.root, classes.hide);

    return (
      <div className={rootClasses}>
        <ul className={classes.ul}>
          {menuItems.map(({ ref, title }) => (
            <li key={title} className={classes.li} onClick={onClose}>
              <AnchorLink className={classes.link} href={ref}>
                <Typography className={classes.typography} align='center'>
                  {title}
                </Typography>
              </AnchorLink>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

MobileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default withStyles(styles)(MobileMenu);
