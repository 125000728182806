import { withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReactLoadingScreen from 'react-loading-screen';

const LoadingScreen = (props) => {
  const { children, isLoading, theme } = props;
  return (
    <ReactLoadingScreen
      loading={isLoading}
      bgColor={theme.palette.grey[900]}
      spinnerColor={theme.palette.secondary.main}>
      {children}
    </ReactLoadingScreen>
  );
};

LoadingScreen.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme()(LoadingScreen);
