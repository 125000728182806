import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  link: {
    textDecoration: 'none',
  },
  typography: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    padding: theme.spacing.unit * 1.25,
    transition: `color ${theme.transitions.duration.standard}ms`,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h4.fontSize,
      padding: theme.spacing.unit * 2,
    },
  },
});

const DesktopMenu = (props) => {
  const { classes, menuItems } = props;
  return (
    <div className={classes.root}>
      {menuItems &&
        menuItems.map(({ ref, title }) => (
          <AnchorLink key={title} className={classes.link} href={ref}>
            <Typography className={classes.typography}>{title}</Typography>
          </AnchorLink>
        ))}
    </div>
  );
};

DesktopMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default withStyles(styles)(DesktopMenu);
