import React from "react";
import ChatterImage from "../../assets/images/p_chatter.png";
import DecisionmakrImage from "../../assets/images/p_decisionmakr.png";
import EpAccountingImage from "../../assets/images/p_epaccounting.png";
import ScannerImage from "../../assets/images/p_nahfeldscanner.jpg";
import NutritionasticImage from "../../assets/images/p_nutritionastic.jpg";
import WorkoutPlannerImage from "../../assets/images/p_workout-planner.png";
import YouTubeImage from "../../assets/images/p_youtube.png";
import TheOnlineBoxImage from "../../assets/images/theonlinebox.png";
import WeightTrackerImage from "../../assets/images/weight-tracker.png";
import BauernhofZollnerImage from "../../assets/images/bauernhof-zollner.jpeg";
import {
  AngularIcon,
  ArduinoIcon,
  CsharpIcon,
  DatabaseIcon,
  FirebaseIcon,
  ReactIcon,
  WordpressIcon,
  YouTubeIcon,
} from "../_shared/SvgIcon";
import Layout from "./Layout";
import Project from "./Project";

const projectList = [
  {
    name: "Bauernhof Zollner",
    chips: [
      {
        label: "Wordpress",
        icon: <WordpressIcon />,
      },
    ],
    image: BauernhofZollnerImage,
    teaser:
      "Die Website bietet gemütliche Ferienwohnungen in idyllischer Umgebung. Neben der Auflistung der Zimmer ist im Hintergrund ein Buchungssystem integriert.",
    websiteUrl: "https://bauernhof-zollner.de/",
  },
  {
    name: "Workout Planner",
    chips: [
      {
        label: "Angular",
        icon: <AngularIcon />,
      },
      {
        label: "C#",
        icon: <CsharpIcon />,
      },
      {
        label: "Firebase/-store",
        icon: <FirebaseIcon />,
      },
    ],
    image: WorkoutPlannerImage,
    teaser:
      "Katalog zum Nachschlagen verschiedener CrossFit Trainingspläne. Unter anderem von Anbietern wie CompTrain, Invuctus und Push Jerk.",
    websiteUrl: "https://woidbua-workout-planner.web.app/",
    repoUrl: "https://gitlab.com/woidbua-public/Workout-Planner",
  },
  {
    name: "Weight Tracker",
    chips: [
      {
        label: "Angular",
        icon: <AngularIcon />,
      },
      {
        label: "Firebase/-store",
        icon: <FirebaseIcon />,
      },
    ],
    image: WeightTrackerImage,
    teaser:
      "Die Weight-Tracker App ermöglicht das Tracken des Gewichts über einen längeren Zeitraum. Der Fortschritt wird visualisiert in einem Liniendiagramm dargestellt.",
    repoUrl: "https://gitlab.com/woidbua-public/weight-tracker",
  },
  {
    name: "TheOnlineBox",
    chips: [
      {
        label: "Wordpress",
        icon: <WordpressIcon />,
      },
    ],
    image: TheOnlineBoxImage,
    teaser: "TheOnlineBox, Funktionielles Live-Online Training für zu Hause.",
    websiteUrl: "https://theonlinebox.de/",
  },
  {
    name: "Nutritionastic",
    chips: [
      {
        label: "Angular",
        icon: <AngularIcon />,
      },
      {
        label: "Firebase/-store",
        icon: <FirebaseIcon />,
      },
    ],
    image: NutritionasticImage,
    teaser:
      "Website zum Tracken der Ernährung. Informationen zu Lebensmittel & Mahlzeiten (Makros), Erstellen von Ernährungsplänen & Essenseinkaufslisten und vieles mehr.",
    youtubeUrl:
      "https://youtube.com/watch?v=3pzn0nllujc&si=EnSIkaIECMiOmarE&t=2584",
  },
  {
    name: "Chatter-App",
    chips: [
      {
        label: "React",
        icon: <ReactIcon />,
      },
      {
        label: "Firebase/-store",
        icon: <FirebaseIcon />,
      },
    ],
    image: ChatterImage,
    teaser:
      "Website, auf der registrierte User Nachrichten posten können. Zusätzlich werden die letzten Aktivitäten im Dashboard angezeigt.",
    repoUrl: "https://gitlab.com/woidbua-public/chatter-app",
    websiteUrl: "https://chatter-app-85ad3.firebaseapp.com/",
  },
  {
    name: "Decisionmakr",
    chips: [
      {
        label: "React",
        icon: <ReactIcon />,
      },
    ],
    image: DecisionmakrImage,
    teaser:
      "Unschlüssig, was als nächstes zu tun ist? Trage alle Optionen ein und lass den Computer entscheiden 😉.",
    repoUrl: "https://gitlab.com/woidbua-public/decisionmakr",
    websiteUrl: "https://decisionmakr.woidbua.com/",
  },
  {
    name: "EMV-Nahfeldscanner",
    chips: [
      {
        label: "Arduino",
        icon: <ArduinoIcon />,
      },
      {
        label: "C#/WPF",
        icon: <CsharpIcon />,
      },
      {
        label: "SQLite",
        icon: <DatabaseIcon />,
      },
    ],
    image: ScannerImage,
    teaser:
      "Bau und Programmierung eines EMV-Nahfeldscanners zur Lokalisierung von Komponenten, die EMV Störungen verursachen.",
    repoUrl: "https://gitlab.com/woidbua-public/nahfeldscanner",
  },
  {
    name: "EpAccounting",
    chips: [
      {
        label: "C#/WPF",
        icon: <CsharpIcon />,
      },
      {
        label: "SQLite",
        icon: <DatabaseIcon />,
      },
    ],
    image: EpAccountingImage,
    teaser: "Software zur Verwaltung von Kunden & Rechnungen.",
    repoUrl: "https://gitlab.com/woidbua-public/EpAccounting",
  },
  {
    name: 'YouTube Kanal - "woidbua"',
    chips: [
      {
        label: "YouTube",
        icon: <YouTubeIcon />,
      },
    ],
    image: YouTubeImage,
    teaser:
      "Aufbau eines Vlog - Channels, der die Themen Fitness & Ernährung behandelt.",
    websiteUrl: "https://www.youtube.com/channel/UCBJSOqhaT74jU9j8gxskX-A",
  },
];

const Projects = () => {
  return (
    <Layout>
      {projectList.map((project, index) => (
        <Project key={index} project={project} />
      ))}
    </Layout>
  );
};

Projects.propTypes = {};

export default Projects;
