import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Snackbar from '../../_shared/Snackbar';

const styles = (theme) => ({
  paper: {
    backgroundColor: 'rgba(51,51,51,0.75)',
    padding: theme.spacing.unit * 2,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    margin: `${theme.spacing.unit}px 0`,
  },
  button: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    transition: `color ${theme.transitions.duration.standard}ms`,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
});

class Form extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    content: '',
    showSuccessDialog: false,
    showErrorDialog: false,
  };

  handleChange = (e) => {
    const target = e.target.name;
    const value = e.target.value;
    this.setState(() => ({
      [target]: value,
    }));
  };

  handleSuccessDialogClose = () => {
    this.setState(() => ({
      showSuccessDialog: false,
    }));
  };

  handleErrorDialogClose = () => {
    this.setState(() => ({
      showErrorDialog: false,
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    var params = new URLSearchParams();
    const { name, email, subject, content } = this.state;
    params.append('name', name);
    params.append('email', email);
    params.append('subject', subject);
    params.append('content', content);

    axios({
      method: 'post',
      url: '/php/sendEmail.php',
      data: params,
    })
      .then(() => {
        this.setState(() => ({
          name: '',
          email: '',
          subject: '',
          content: '',
          showSuccessDialog: true,
        }));
      })
      .catch(() => {
        this.setState(() => ({
          showErrorDialog: true,
        }));
      });
  };

  render() {
    const { classes } = this.props;
    const { showSuccessDialog, showErrorDialog } = this.state;

    return (
      <Paper className={classes.paper} elevation={4}>
        <form className={classes.form} method='POST' autoComplete='off' onSubmit={this.onSubmit}>
          <TextField
            className={classes.textField}
            label='Name'
            name='name'
            fullWidth
            required
            value={this.state.name}
            onChange={this.handleChange}
          />
          <TextField
            className={classes.textField}
            label='E-Mail'
            name='email'
            type='email'
            fullWidth
            required
            value={this.state.email}
            onChange={this.handleChange}
          />
          <TextField
            className={classes.textField}
            label='Betreff'
            name='subject'
            fullWidth
            required
            value={this.state.subject}
            onChange={this.handleChange}
          />
          <TextField
            className={classes.textField}
            label='Anfrage'
            name='content'
            fullWidth
            required
            value={this.state.content}
            onChange={this.handleChange}
            multiline
            rows='8'
          />
          <Button className={classes.button} color='secondary' type='submit' variant='contained' fullWidth>
            Senden
          </Button>
        </form>
        <Snackbar
          isOpen={showSuccessDialog}
          message='Anfrage erfolgreich versendet.'
          onClose={this.handleSuccessDialogClose}
          variant='success'
        />
        <Snackbar
          isOpen={showErrorDialog}
          message='Die Anfrage konnte nicht versendet werden.'
          onClose={this.handleErrorDialogClose}
          variant='error'
        />
      </Paper>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Form);
