import { withStyles, withWidth } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Backdrop from './Backdrop';
import BurgerButton from './BurgerButton';
import Logo from './Logo';
import { DesktopMenu, MobileMenu } from './Menu';

const styles = (theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
  },
  toolbar: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    },
    zIndex: 200,
  },
});

const menu = [
  {
    ref: '#about',
    title: 'About',
  },
  { ref: '#skills', title: 'Skills' },
  {
    ref: '#projects',
    title: 'Projekte',
  },
  {
    ref: '#contact',
    title: 'Kontakt',
  },
];

class NavBar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
  };

  state = {
    isMobileMenuOpen: false,
  };

  handleMobileMenuToggle = (e) => {
    this.setState((prevState) => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }));
  };

  handleMobileMenuClose = (e) => {
    this.setState(() => ({
      isMobileMenuOpen: false,
    }));
  };

  render() {
    const { classes, width } = this.props;
    const { isMobileMenuOpen } = this.state;

    const isMobile = width === 'xs' ? true : false;

    return (
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Logo />
          {isMobile ? (
            <MobileMenu isOpen={isMobileMenuOpen} menuItems={menu} onClose={this.handleMobileMenuClose} />
          ) : (
            <DesktopMenu menuItems={menu} />
          )}

          <BurgerButton isActive={isMobileMenuOpen} onClick={this.handleMobileMenuToggle} />
        </Toolbar>
        <Backdrop isActive={isMobileMenuOpen} onClick={this.handleMobileMenuClose} />
      </AppBar>
    );
  }
}

export default withStyles(styles)(withWidth()(NavBar));
