import { withStyles } from "@material-ui/core";
import PropTypes, { object } from "prop-types";
import React from "react";
import CVImage from "../../../../assets/images/cv_me.jpg";

const styles = (theme) => ({
  root: {
    height: "100%",
  },
  section: {
    height: "100%",
  },
  img: {
    padding: theme.spacing.unit,
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "25% center",
  },
});

const Portrait = (props) => {
  const { classes } = props;
  return (
    <section id="about" className={classes.section}>
      <div className={classes.root}>
        <img className={classes.img} src={CVImage} alt="Portrait" />
      </div>
    </section>
  );
};

Portrait.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Portrait);
