import { withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const styles = (theme) => ({
  root: {
    margin: theme.spacing.unit,
    '&:not(:last-child)': {
      marginBottom: theme.spacing.unit * 2.5,
    },
  },
  descriptionContainerSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  descriptionContainerStart: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  descriptionBold: {
    color: theme.palette.primary.dark,
    fontWeight: 700,
  },
  descriptionUnderline: {
    color: theme.palette.primary.dark,
    textDecorationLine: 'underline',
  },
  skillLevelEntry: {
    color: 'blue',
    fontWeight: 700,
  },
  skillLevelBasic: {
    color: 'green',
    fontWeight: 700,
  },
  skillLevelIntermediate: {
    color: 'orange',
    fontWeight: 700,
  },
  skillLevelAdvanced: {
    color: 'red',
    fontWeight: 700,
  },
});

const Skill = (props) => {
  const { classes, title, percentage, usage } = props;

  let skillLevel = <Typography className={classes.skillLevelEntry}>✍️ Entry</Typography>;
  if (percentage >= 25 && percentage < 50) {
    skillLevel = <Typography className={classes.skillLevelBasic}>👌 Basic</Typography>;
  } else if (percentage >= 50 && percentage < 75) {
    skillLevel = <Typography className={classes.skillLevelIntermediate}>📚 Intermediate</Typography>;
  } else if (percentage >= 75) {
    skillLevel = <Typography className={classes.skillLevelAdvanced}>🎓 Advanced</Typography>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.descriptionContainerSpaceBetween}>
        <Typography className={classes.descriptionBold}>{title}</Typography>
        {percentage != null && <Fragment>{skillLevel}</Fragment>}
      </div>
      {percentage != null && (
        <Fragment>
          <LinearProgress color='secondary' variant='determinate' value={percentage} />
        </Fragment>
      )}
      <div className={classes.descriptionContainerStart}>
        <Typography className={classes.descriptionUnderline}>Verwendung:</Typography>
        <Typography>{usage}</Typography>
      </div>
    </div>
  );
};

Skill.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default withStyles(styles)(Skill);
