import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import { createMuiTheme } from '@material-ui/core/styles';

export const lightTheme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: orange,
    background: {
      default: '#dedede',
    },
  },
  typography: {
    fontSize: 14,
    useNextVariants: true,
  },
  spacing: {
    unit: 8,
    minWidth: 300,
  },
});

export const darkTheme = createMuiTheme({
  ...lightTheme,
  palette: {
    primary: grey,
    secondary: orange,
    type: 'dark',
  },
});
