import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    ...theme.typography.h3,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    paddingBottom: theme.spacing.unit * 2,
    textDecoration: 'underline',
  },
});

const Subtitle = (props) => {
  const { children, classes } = props;
  return (
    <Typography className={classes.root} align='center'>
      {children}
    </Typography>
  );
};

Subtitle.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Subtitle);
