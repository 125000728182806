import { MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component, Fragment } from 'react';
import About from '../components/About';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Helmet from '../components/Helmet';
import Hero from '../components/Hero';
import LoadingScreen from '../components/LoadingScreen';
import NavBar from '../components/NavBar';
import Projects from '../components/Projects';
import { darkTheme, lightTheme } from '../styles/theme';

class Homepage extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    setTimeout(
      () =>
        this.setState(() => ({
          isLoading: false,
        })),
      500
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <MuiThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Helmet />
        <LoadingScreen isLoading={isLoading}>
          {isLoading ? (
            <div />
          ) : (
            <Fragment>
              <NavBar />
              <Hero />
              <About />
              <Projects />
              <MuiThemeProvider theme={darkTheme}>
                <Contact />
                <Footer />
              </MuiThemeProvider>
            </Fragment>
          )}
        </LoadingScreen>
      </MuiThemeProvider>
    );
  }
}

export default Homepage;
