import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Container from '../../_shared/Container';
import Subtitle from '../../_shared/Subtitle';

const styles = (theme) => ({
  projectContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      justifyContent: 'space-between',
    },
  },
});

const Layout = (props) => {
  const { children, classes } = props;
  return (
    <Container>
      <section id='projects'>
        <Subtitle>Projekte</Subtitle>
      </section>
      <div className={classes.projectContainer}>{children}</div>
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Layout);
