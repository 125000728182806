import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

const MediaButton = (props) => {
  const { icon, url } = props;

  return (
    <IconButton href={url} target='_blank'>
      {icon}
    </IconButton>
  );
};

MediaButton.propTypes = {
  icon: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
};

export default MediaButton;
