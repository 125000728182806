import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiSnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

const contentStyles = (theme) => ({
  success: {
    backgroundColor: green[600],
    color: 'whitesmoke',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: 'whitesmoke',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const SnackbarContent = (props) => {
  const { classes, className, message, onClose, variant } = props;
  const Icon = variantIcon[variant];

  return (
    <MuiSnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
};

SnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success', 'error']).isRequired,
};

const SnackbarContentWrapper = withStyles(contentStyles)(SnackbarContent);

const styles = (theme) => ({
  margin: {
    margin: theme.spacing.unit * 3,
  },
});

const Snackbar = (props) => {
  const { classes, isOpen, onClose, message, variant } = props;

  return (
    <MuiSnackbar className={classes.margin} open={isOpen} autoHideDuration={6000} onClose={onClose}>
      <SnackbarContentWrapper onClose={onClose} message={message} variant={variant} />
    </MuiSnackbar>
  );
};

Snackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['success', 'error']).isRequired,
};

export default withStyles(styles)(Snackbar);
