import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';

const styles = (theme) => ({
  root: {
    '& > div > div': {
      borderTop: `7px solid ${theme.palette.secondary.main}`,
    },
  },
  divider: {
    margin: `${theme.spacing.unit}px 0`,
  },
  elementTitle: {
    color: `${theme.palette.primary.main}!important`,
    fontSize: `${theme.typography.fontSize * 1.25}px!important`,
    fontWeight: 'bold!important',
    margin: '0!important',
  },
  elementSubtitle: {
    fontSize: `${theme.typography.fontSize * 1.1}px!important`,
    fontWeight: 'bold!important',
    margin: '0!important',
  },
  elementContentTitle: {
    fontWeight: 'bold!important',
    margin: '0!important',
    textDecoration: 'underline',
  },
  elementContent: {
    margin: '0!important',
  },
});

const iconTheme = (theme) => ({
  primary: {
    background: theme.palette.error.dark,
    color: 'white',
  },
  ternary: {
    background: theme.palette.secondary.light,
    color: 'white',
  },
  secondary: {
    background: theme.palette.secondary.dark,
    color: 'white',
  },
});

const Element = (props) => {
  const { classes, icon, iconStyle, title, subtitle, contentItems, theme } = props;
  const iTheme = iconTheme(theme)[iconStyle];

  return (
    <VerticalTimelineElement
      className={classNames('vertical-timeline-element--work', classes.root)}
      icon={icon}
      iconStyle={iTheme}>
      <Typography className={classes.elementTitle}>{title}</Typography>
      <Typography className={classes.elementSubtitle}>{subtitle}</Typography>
      <Divider className={classes.divider} />
      {contentItems.map(({ title, content }) => (
        <div key={title}>
          <Typography className={classes.elementContentTitle}>{title}</Typography>
          <Typography className={classes.elementContent}>{content}</Typography>{' '}
        </div>
      ))}
    </VerticalTimelineElement>
  );
};

Element.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.node.isRequired,
  iconStyle: PropTypes.oneOf(['primary', 'secondary', 'ternary']),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  contentItems: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
};

Element.defaultProps = {
  iconStyle: 'primary',
};

export default withStyles(styles)(withTheme()(Element));
