import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Container from '../../../_shared/Container';
import Subtitle from '../../../_shared/Subtitle';

const Layout = (props) => {
  const { sections: Sections } = props;

  return (
    <Container>
      <section id='skills'>
        <Subtitle>Skills</Subtitle>
      </section>
      <Paper elevation={4}>
        <Sections />
      </Paper>
    </Container>
  );
};

Layout.propTypes = {
  sections: PropTypes.func.isRequired,
};

export default Layout;
