import React from 'react';
import Form from './Form';
import Layout from './Layout';

const Contact = () => {
  return (
    <Layout>
      <Form />
    </Layout>
  );
};

Contact.propTypes = {};

export default Contact;
