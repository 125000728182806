import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {
  GitLabIcon,
  GitHubIcon,
  LinkedInIcon,
  XingIcon,
  YouTubeIcon,
} from "../../../_shared/SvgIcon";
import MediaButton from "./MediaButton";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing.unit * 0.5,
    width: "100%",
  },
});

const iconList = [
  {
    name: "GitLab",
    icon: <GitLabIcon />,
    url: "https://gitlab.com/woidbua93",
  },
  {
    name: "GitHub",
    icon: <GitHubIcon />,
    url: "https://github.com/woidbua",
  },
  {
    name: "LinkedIn",
    icon: <LinkedInIcon />,
    url: "https://www.linkedin.com/in/%F0%9F%8C%B2-andre-multerer-%F0%9F%9A%80-53448a164/",
  },
  {
    name: "Xing",
    icon: <XingIcon />,
    url: "https://www.xing.com/profile/Andre_Multerer",
  },
  {
    name: "YouTube",
    icon: <YouTubeIcon />,
    url: "https://www.youtube.com/channel/UCBJSOqhaT74jU9j8gxskX-A",
  },
];

const MediaButtons = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      {iconList.map(({ name, icon, url }) => (
        <MediaButton key={name} icon={icon} url={url} />
      ))}
    </div>
  );
};

MediaButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaButtons);
