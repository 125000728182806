import React from 'react';
import Description from './Description';
import Layout from './Layout';
import MediaButtons from './MediaButtons';
import Portrait from './Portrait';

const Introduction = () => {
  return <Layout leftPanel={Portrait} rightPanel={Description} bottomPanel={MediaButtons} />;
};

Introduction.propTypes = {};

export default Introduction;
