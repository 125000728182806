import React from 'react';
import Layout from './Layout';
import Timeline from './Timeline';

const CV = () => {
  return <Layout content={Timeline} />;
};

CV.propTypes = {};

export default CV;
