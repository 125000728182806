import React, { Fragment } from 'react';
import Databases from './Databases';
import Frameworks from './Frameworks';
import Languages from './Languages';
import Others from './Others';

const Sections = () => {
  return (
    <Fragment>
      <Languages />
      <Frameworks />
      <Databases />
      <Others />
    </Fragment>
  );
};

Sections.propTypes = {};

export default Sections;
