import React, { Fragment } from 'react';
import Section from '../Section';
import Skill from '../Skill';

const Others = () => {
  const leftPanel = () => (
    <Fragment>
      <Skill
        title='Git'
        percentage={95}
        usage={'Grollmus GmbH, Adesso SE, Smartricity GmbH, Vector Informatik GmbH, Studium, Private Projekte'}
      />
      <Skill
        title='Testing (Unit Tests, Integration Tests, E2E)'
        percentage={90}
        usage={'Grollmus GmbH, Adesso SE, Smartricity GmbH, Studium (Masterarbeit), Private Projekte'}
      />
      <Skill title='Terraform' percentage={85} usage={'Grollmus GmbH'} />
    </Fragment>
  );

  const rightPanel = () => (
    <Fragment>
      <Skill title='AWS' percentage={80} usage={'Grollmus GmbH, Smartricity GmbH'} />
      <Skill title='Azure DevOps' percentage={70} usage={'Grollmus GmbH, Adesso SE'} />
      <Skill title='Agile (Scrum)' percentage={70} usage={'Grollmus GmbH'} />
      <Skill title='Videobearbeitung (Davinci Resolve)' percentage={65} usage={'Private Projekte'} />
    </Fragment>
  );

  return <Section title='Sonstiges' leftPanel={leftPanel} rightPanel={rightPanel} />;
};

Others.propTypes = {};

export default Others;
