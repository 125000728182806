import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import { withStyles, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import PropTypes from "prop-types";
import React from "react";
import { GitLabIcon, YouTubeIcon } from "../../_shared/SvgIcon";

const styles = (theme) => ({
  card: {
    display: "flex",
    background: "rgba(250,250,250,0.8)",
    flexDirection: "column",
    minWidth: 280,
    width: "100%",
    maxWidth: 420,
    margin: `${theme.spacing.unit}px 0`,
    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing.unit * 2}px 0`,
    },
  },
  title: {
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: "bold",
  },
  chipContainer: {
    margin: `${theme.spacing.unit}px 0`,
  },
  chip: {
    margin: theme.spacing.unit * 0.5,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  content: {
    flexGrow: 1,
  },
  actions: {
    display: "flex",
    alignItems: "flex-end",
  },
});

const Project = (props) => {
  const { classes, theme, project } = props;
  const {
    name,
    chips,
    image,
    teaser,
    repoUrl,
    websiteUrl,
    youtubeUrl,
  } = project;

  const title = (
    <Typography className={classes.title} color="primary">
      {name}
    </Typography>
  );

  const subheader = (
    <div className={classes.chipContainer}>
      {chips.map(({ label, icon }, index) => (
        <Chip
          key={index}
          className={classes.chip}
          label={label}
          avatar={<Avatar>{icon}</Avatar>}
        />
      ))}
    </div>
  );

  return (
    <Card className={classes.card}>
      <CardHeader title={title} subheader={subheader} />
      <CardMedia className={classes.media} image={image} title={name} />
      <CardContent className={classes.content}>
        <Typography>{teaser}</Typography>
      </CardContent>
      <CardActions className={classes.actions} disableActionSpacing>
        {repoUrl && (
          <IconButton aria-label="Repository" href={repoUrl} target="_blank">
            <GitLabIcon fill={theme.palette.primary.dark} />
          </IconButton>
        )}
        {websiteUrl && (
          <IconButton aria-label="Website" href={websiteUrl} target="_blank">
            <HomeIcon />
          </IconButton>
        )}
        {youtubeUrl && (
          <IconButton aria-label="YouTube" href={youtubeUrl} target="_blank">
            <YouTubeIcon fill={theme.palette.primary.dark} />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

Project.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTheme()(Project));
