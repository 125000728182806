import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from '../../../assets/images/bg_contact.jpg';
import Container from '../../_shared/Container';
import Subtitle from '../../_shared/Subtitle';

const styles = (theme) => ({
  root: {
    backgroundColor: 'rgba(51,51,51,0.8)',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
  },
});

const Layout = (props) => {
  const { classes, children } = props;
  return (
    <div className={classes.root}>
      <Container>
        <section id='contact'>
          <Subtitle>Kontakt</Subtitle>
        </section>
        {children}
      </Container>
    </div>
  );
};

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Layout);
