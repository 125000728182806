import React, { Fragment } from 'react';
import Section from '../Section';
import Skill from '../Skill';

const Frameworks = () => {
  const leftPanel = () => (
    <Fragment>
      <Skill title='Angular (Ionic, ngrx)' percentage={85} usage={'Grollmus GmbH, Adesso SE, Private Projekte'} />
      <Skill title='ASP.NET' percentage={90} usage={'Grollmus GmbH, Adesso SE, Private Projekte'} />
      <Skill
        title='WPF/WinForms'
        percentage={85}
        usage={'Grollmus GmbH, Vector Informatik GmbH, Studium (Bachelorarbeit), Private Projekte'}
      />
    </Fragment>
  );

  const rightPanel = () => (
    <Fragment>
      <Skill title='Django' percentage={65} usage={'Smartricity GmbH'} />
      <Skill title='Wordpress' percentage={60} usage={'Private Projekte'} />
      <Skill title='React' percentage={25} usage={'Private Projekte (z.B. diese Website)'} />
    </Fragment>
  );

  return <Section title='Frameworks' leftPanel={leftPanel} rightPanel={rightPanel} />;
};

Frameworks.propTypes = {};

export default Frameworks;
