import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import img_hero from '../../../assets/images/hero.jpg';

const styles = (theme) => ({
  root: {
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '0 0 15vh 0',
    height: '100vh',
    width: '100%',
    minWidth: 0,
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 10vw 0 0',
    },
  },
  content: {
    opacity: 0,
    transform: 'translateY(50px)',
    transition: 'opacity 1s ease-in 1s, transform 1s ease-in 1s',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    objectFit: 'cover',
    objectPosition: '0 50%',
    opacity: 0,
    transition: 'opacity 1.5s ease-in',
  },
  show: {
    transform: 'translateY(0)',
    opacity: 1,
  },
});

class Layout extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.func.isRequired,
  };

  state = {
    didMount: false,
  };

  componentDidMount() {
    this.setState(() => ({
      didMount: true,
    }));
  }

  render() {
    const { classes, content: Content } = this.props;
    const { didMount } = this.state;

    const imageClasses = didMount ? classNames(classes.image, classes.show) : classNames(classes.image);

    const contentClasses = didMount ? classNames(classes.content, classes.show) : classNames(classes.content);

    return (
      <div className={classes.root}>
        <img className={imageClasses} src={img_hero} alt='Hero background' />
        <Content className={contentClasses} />
      </div>
    );
  }
}

export default withStyles(styles)(Layout);
