import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from "react";
import Container from "../../../_shared/Container";

const styles = (theme) => ({
  paper: {},
  container: {
    alignItems: "stretch",
  },
  leftPanel: {},
  rightPanel: {},
  bottomPanel: {},
});

const Layout = (props) => {
  const {
    classes,
    leftPanel: LeftPanel,
    rightPanel: RightPanel,
    bottomPanel: BottomPanel,
  } = props;

  return (
    <Container>
      <Paper className={classes.paper} elevation={4}>
        <Grid container className={classes.container}>
          <Grid className={classes.leftPanel} item xs={12} sm={6}>
            <LeftPanel />
          </Grid>
          <Grid className={classes.rightPanel} item xs={12} sm={6}>
            <RightPanel />
          </Grid>
          <Grid className={classes.bottomPanel} item xs={12}>
            <BottomPanel />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  leftPanel: PropTypes.func.isRequired,
  rightPanel: PropTypes.func.isRequired,
  bottomPanel: PropTypes.func.isRequired,
};

export default withStyles(styles)(Layout);
