import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: `${theme.spacing.unit}px 0`,
    width: '100%',
  },
  titleContainer: {
    minWidth: 90,
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.fontSize * 1.1,
    },
  },
  contentContainer: {
    flexGrow: 1,
  },
  content: {
    color: theme.palette.text.secondary,
    '&:not(:first-child)': {
      marginTop: theme.spacing.unit * 0.5,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.fontSize * 1.1,
    },
  },
});

const Item = (props) => {
  const { classes, title, content } = props;

  const contentItems = typeof content === 'string' ? content.split(',').map((item) => item.trim()) : [content];

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
      </div>
      <div className={classes.contentContainer}>
        {contentItems.map((item) => (
          <Typography key={item} className={classes.content}>
            {item}
          </Typography>
        ))}
      </div>
    </div>
  );
};

Item.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
};

export default withStyles(styles)(Item);
