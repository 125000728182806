import { withStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Item from "./Item";

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit,
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize * 2,
  },
  subtitle: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.fontSize * 1.5,
  },
  link: {
    color: theme.palette.secondary.light,
    textDecoration: "none",
    transition: `color ${theme.transitions.duration.standard}ms`,
    "&:hover": {
      color: theme.palette.secondary.dark,
    },
  },
});

const Description = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Andre Multerer</Typography>
      <Typography className={classes.subtitle}>
        <span role="img" aria-label="graduation hat">
          💻 Software-Entwickler
        </span>
      </Typography>
      <Divider />
      <Item title="Alter" content={moment().diff("1993-11-06", "years")} />
      <Item title="Addresse" content="Pickelstraße 10, 80637 München" />
      <Item
        title="E-Mail"
        content={
          <a className={classes.link} href="mailto:andre.multerer@gmail.com">
            andre.multerer@gmail.com
          </a>
        }
      />
      <Item title="Mobil" content="+49 175 91-11-453" />
      <Item
        title="Interessen"
        content="Calisthenics, Bouldern/Klettern, Programmieren, Gaming, Videobearbeitung"
      />
    </div>
  );
};

Description.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Description);
