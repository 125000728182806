import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import favicon from '../../assets/logos/favicon.ico';

const Helmet = () => (
  <ReactHelmet defaultTitle='woidbua'>
    <meta charset='UTF-8' />
    <meta name='viewport' content='width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover' />
    <meta http-equiv='X-UA-Compatible' content='ie=edge' />

    <link rel='shortcut icon' href={favicon} />
    <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Coiny|Lobster|Roboto' />
    <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons' />
  </ReactHelmet>
);

export default Helmet;
