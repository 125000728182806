import PropTypes from 'prop-types';
import React from 'react';
import Container from '../../../_shared/Container';
import Subtitle from '../../../_shared/Subtitle';

const Layout = (props) => {
  const { content: Content } = props;

  return (
    <Container>
      <Subtitle>Lebenslauf</Subtitle>
      <Content />
    </Container>
  );
};

Layout.propTypes = {
  content: PropTypes.func.isRequired,
};

export default Layout;
