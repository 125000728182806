import React, { Fragment } from "react";
import Section from "../Section";
import Skill from "../Skill";

const Databases = () => {
  const leftPanel = () => (
    <Fragment>
      <Skill
        title="SQL (MSSQL, MySQL, PostreSQL, SQLite)"
        percentage={80}
        usage={"Grollmus GmbH, Adesso SE, Studium, Private Projekte"}
      />
      <Skill title="MongoDB" percentage={75} usage={"Grollmus GmbH, Private Projekte"} />
      <Skill
        title="Firebase/Firestore"
        percentage={70}
        usage={"Private Projekte"}
      />
    </Fragment>
  );

  const rightPanel = () => (
    <Fragment>
      <Skill title="ElasticSearch" percentage={60} usage={"Smartricity GmbH"} />
      <Skill title="GraphDB" percentage={35} usage={"Studium (Masterarbeit)"} />
    </Fragment>
  );

  return (
    <Section
      title="Datenbanken"
      leftPanel={leftPanel}
      rightPanel={rightPanel}
    />
  );
};

Databases.propTypes = {};

export default Databases;
